import React from 'react';
import Layout from "../../components/Layout";

const Thanks = ({location}) => {
    return (
        <Layout location={location}>
            <section className="section" style={{height: '500px'}}>
                <div className="container has-text-centered" style={{marginTop: '170px'}}>
                    <div className="content">
                        <h1>Dziękujemy</h1>
                        <p>Twoja wiadomość została wysłana.</p>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Thanks;